<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.94008 7.99603C2.66741 9.1477 4.8324 12.0591 8.1317 12.0591C9.46203 12.0591 10.544 11.6377 11.4856 10.9972C12.8829 10.0494 13.8315 8.73243 14.2667 8.04355C14.3551 7.90357 14.3993 7.83358 14.424 7.7261C14.4425 7.64536 14.4425 7.51802 14.424 7.43728C14.3993 7.32979 14.3553 7.2601 14.2673 7.12072C13.54 5.96904 11.375 3.05762 8.07567 3.05762C6.74535 3.05762 5.59944 3.53096 4.65784 4.17143C3.26052 5.11932 2.37588 6.38431 1.9407 7.0732C1.85228 7.21317 1.80807 7.28316 1.78342 7.39065C1.76491 7.47138 1.7649 7.59873 1.7834 7.67946C1.80804 7.78695 1.85205 7.85665 1.94008 7.99603Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="8.13281" cy="7.53516" r="2" stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: "IconEyeOutlined"
}
</script>

